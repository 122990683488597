import React from 'react'
import { useLocation } from '@reach/router'
import { Link, Typography, AppBar, Toolbar, Container, Box, Tabs, Tab } from '@mui/material'

const Header = ({ siteTitle }: any) => {
    const { pathname } = useLocation()
    let tabValue: number | boolean = ['/', '/blog', '/tag'].findIndex((url) => url === pathname)
    tabValue = tabValue === -1 ? false : tabValue
    return (
        <Box
            sx={{
                borderBottom: 'solid 1px #eee',
            }}
        >
            <Container disableGutters maxWidth="lg">
                <AppBar
                    position="static"
                    color="inherit"
                    sx={{
                        boxShadow: 'none',
                    }}
                >
                    <Toolbar>
                        <Typography
                            variant="h2"
                            component="div"
                            sx={{ flexGrow: 1, textTransform: 'none' }}
                        >
                            <Link href="/" color="inherit" underline="none">
                                {siteTitle}
                            </Link>
                        </Typography>
                        <Tabs
                            value={tabValue}
                            variant="scrollable"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            <Tab component="a" label="新着記事" href="/" />
                            <Tab component="a" label="ブログ一覧" href="/blog" />
                            <Tab component="a" label="タグ" href="/tag" />
                        </Tabs>
                    </Toolbar>
                </AppBar>
            </Container>
        </Box>
    )
}

export default Header
