import { useStaticQuery, graphql } from 'gatsby'
export const useSiteMetadata = () => {
    const data = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        siteUrl
                        siteTitle
                        siteDescription
                    }
                }
                siteIcon: file(relativePath: { eq: "icon.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            width: 180
                            height: 180
                            transformOptions: { cropFocus: CENTER }
                            quality: 90
                        )
                    }
                }
                siteOgImage: file(relativePath: { eq: "og_image.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            width: 1200
                            height: 630
                            transformOptions: { cropFocus: CENTER }
                            quality: 90
                        )
                    }
                }
                defaultNoimage: file(relativePath: { eq: "default_noimage.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 320
                            height: 180
                            transformOptions: { cropFocus: CENTER }
                            quality: 90
                        )
                    }
                }
            }
        `
    )
    return data
}
