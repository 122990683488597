import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { getSrc } from 'gatsby-plugin-image'
import { Container, Box, Tabs, Tab, Fab, Alert } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Header from './header'
import Footer from './footer'
import '../styles/style.css'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Layout = ({ children, title = '', description = '', ogImageUrl = '' }: any) => {
    const [isScrolled, setIsScrolled] = useState(false)
    const [windowScrollY, setWindowScrollY] = useState(0)

    const scrollListener = (e: any) => {
        const windowScrollY = window.scrollY
        setWindowScrollY(windowScrollY)
        setIsScrolled(true)
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollListener)
        return () => {
            window.removeEventListener('scroll', scrollListener)
        }
    }, [])

    useEffect(() => {
        if (isScrolled) {
            console.log('isScrolled', isScrolled)
            // google AdSense
            const googleAdsenseId = 'ca-pub-9459277760652211'
            let script = document.createElement('script')
            script.async = true
            script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdsenseId}`
            script.crossOrigin = 'anonymous'
            document.body.appendChild(script)

            // google Analytics
            const googleAnalyticsId = 'G-ZRHY3S0XMQ'
            script = document.createElement('script')
            script.async = true
            script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
            document.body.appendChild(script)

            script = document.createElement('script')
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${googleAnalyticsId}');
            `
            document.body.appendChild(script)

            // はてなブックマーク
            script = document.createElement('script')
            script.async = true
            script.src = `https://b.st-hatena.com/js/bookmark_button.js`
            document.body.appendChild(script)
        }
    }, [isScrolled])

    const { pathname } = useLocation()
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle, siteDescription },
        },
        siteIcon,
        siteOgImage,
    } = useSiteMetadata()

    const siteIconUrl = siteUrl + getSrc(siteIcon)
    const siteOgImageUrl = siteUrl + getSrc(siteOgImage)
    const canonicalUrl = siteUrl + pathname

    title = title || siteTitle
    description = description || siteDescription
    ogImageUrl = ogImageUrl || siteOgImageUrl

    let tabValue: number | boolean = ['/', '/blog', '/tag'].findIndex((url) => url === pathname)
    tabValue = tabValue === -1 ? false : tabValue

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>{title}</title>
                <meta name="description" content={description}></meta>
                <meta name="thumbnail" content={ogImageUrl}></meta>
                <link rel="icon" href="/favicon.ico" />
                <meta property="og:locale" content="ja_JP"></meta>
                <meta property="og:title" content={title}></meta>
                <meta property="og:description" content={description}></meta>
                <meta property="og:image" content={ogImageUrl}></meta>
                <meta property="og:image:width" content="1200"></meta>
                <meta property="og:image:height" content="630"></meta>
                <meta property="og:url" content={canonicalUrl}></meta>
                <meta property="og:site_name" content={siteTitle}></meta>
                <meta name="twitter:title" content={title}></meta>
                <meta name="twitter:description" content={description}></meta>
                <meta name="twitter:image" content={ogImageUrl}></meta>
                <meta name="twitter:card" content="summary_large_image"></meta>
                <link rel="apple-touch-icon" href={siteIconUrl}></link>
                <link rel="apple-touch-icon" sizes="180x180" href={siteIconUrl}></link>
                <meta name="msapplication-TileImage" content={siteIconUrl}></meta>
                <meta name="robots" content="index,follow"></meta>
                <meta name="googlebot" content="index,follow"></meta>
                <link rel="canonical" href={canonicalUrl}></link>
            </Helmet>
            <Header siteTitle={siteTitle} />
            <Container disableGutters maxWidth="lg">
                {pathname === '/' && (
                    <Alert severity="info">2022年5月13日 HugoからGatsbyに移行しました</Alert>
                )}

                <Tabs
                    value={tabValue}
                    variant="scrollable"
                    sx={{ display: { xs: 'block', sm: 'none' } }}
                >
                    <Tab component="a" label="新着記事" href="/" />
                    <Tab component="a" label="ブログ一覧" href="/blog" />
                    <Tab component="a" label="タグ" href="/tag" />
                </Tabs>
                <Box
                    component="main"
                    sx={{
                        minHeight: '70vh',
                    }}
                >
                    {children}
                </Box>
            </Container>
            <Footer />
            <Fab
                color="primary"
                aria-label="up"
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: windowScrollY < 1000 ? -60 : 20,
                    opacity: windowScrollY < 1000 ? '0' : '1',
                    transition: '0.5s',
                }}
                onClick={() => {
                    window.scrollTo({ top: 0 })
                }}
            >
                <ArrowUpwardIcon />
            </Fab>
        </>
    )
}

export default Layout
