import React from 'react'
import { Link, Box, Grid, Typography } from '@mui/material'

const Footer = () => {
    return (
        <Box component="footer" sx={{ mt: 8, borderTop: 'solid 1px #eee', background: '#fcfcfc' }}>
            <Grid
                container
                item
                xs={11}
                sm={12}
                md={12}
                justifyContent="center"
                sx={{ mt: 4, mx: 'auto' }}
            >
                <Grid item container direction="column" xs={'auto'} sm={1} md={1} />
                <Grid item container direction="column" xs={5} sm={3} md={3}>
                    <Grid item sx={{ mt: 1 }}>
                        <Link href="/about" variant="body2" color="inherit" underline="none">
                            このサイトについて
                        </Link>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <Link href="/information" variant="body2" color="inherit" underline="none">
                            お知らせ
                        </Link>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={1} sm={1} md={1} />
                <Grid item container direction="column" xs={5} sm={3} md={3}>
                    <Grid item sx={{ mt: 1 }}>
                        <Link href="/privacy" variant="body2" color="inherit" underline="none">
                            プライバシーポリシー
                        </Link>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <Link href="/contact" variant="body2" color="inherit" underline="none">
                            お問い合わせ
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            <Typography
                variant="body2"
                color="text.secondary"
                sx={{ py: 2, textAlign: 'center', fontSize: 12 }}
            >
                © 2022{' '}
                <Link
                    href="https://github.com/okdyy75"
                    color="inherit"
                    underline="none"
                    sx={{ ml: 1 }}
                >
                    okdyy75
                </Link>
                <br />
                Powered by
                <Link
                    href="https://www.gatsbyjs.com/"
                    color="inherit"
                    underline="none"
                    sx={{ ml: 1 }}
                >
                    Gatsby
                </Link>
                ,
                <Link href="https://mui.com/" color="inherit" underline="none" sx={{ ml: 1 }}>
                    MUI
                </Link>
            </Typography>
        </Box>
    )
}

export default Footer
